
	import { defineComponent, PropType } from "vue";
	import LogicDesignerConditionContainer from "./LogicDesignerConditionContainer.vue";
	import { LogicBlock, LogicBooleanOperator, LogicExpressionItem } from "./LogicModel";

	export default defineComponent({
		components: { LogicDesignerConditionContainer },
		props: {
			model: {
				type: Object as PropType<LogicBlock>,
				required: true
			},
			isRoot: {
				type: Boolean,
				required: false,
				default: false
			},
			expressionItems: {
				type: Array as PropType<LogicExpressionItem[]>,
				required: false
			}
		},
		data() {
			return {
				block: this.model
			}
		},
		methods: {
			blockAdd(parent: LogicBlock) {
				parent.blocks.push({
					blocks: [],
					conditions: [],
					booleanOperator: LogicBooleanOperator.AND,
					return: undefined
				});
			},
			blockRemove(subBlock: LogicBlock) {
				const subBlockIndex = this.block.blocks.indexOf(subBlock);
				if (subBlockIndex > -1) {
					this.block.blocks.splice(subBlockIndex, 1);
				}
			}			
		}
	});
