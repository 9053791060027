import { Validatable } from "./Validatable";

export abstract class ValidatorBase<T = Validatable> {
    validatorName: string;
    propertyKey: string;
    isValid: boolean;
    errorMessage?: (t: T) => string;
    
    constructor(validatorName: string, propertyKey: string, errorMessage: ((t: T) => string) | undefined = undefined) {
        this.validatorName = validatorName;
        this.propertyKey = propertyKey;
        this.isValid = true;
        this.errorMessage = errorMessage;
    }

    getStringValue(instance: T, propertyKey: string | undefined = undefined) {
        const value = ((instance as any)[propertyKey || this.propertyKey])?.toString() as string;
        return value ? value.trim().toLowerCase() : "";
    }
    
    abstract validate(instance: T): Promise<boolean>;
}