
	import { Shared } from "@/localization/LanguageData";
	import LocalizationManager, { LocalizationManagerContext } from "@/localization/LocalizationManager";
	import { defineComponent, PropType } from "vue";

	interface DropDownData {
		localization: LocalizationManagerContext;
		itemsFiltered: any[];
		itemsVisible: boolean;
		selectedIndex: number;
		searchExpression?: string;
		searchTimeout: number;
		bodyClickBound: () => any;
	}

	const DropDown = defineComponent({
		props: {
			modelValue: {
				type: Object as PropType<any>,
				required: false
			},
			items: {
				type: Array as PropType<any[]>,
				required: true
			},
			searchFunction: {
				type: Function as PropType<(i: any, e: string) => boolean>,
				required: false
			},
			wider: {
				type: Boolean,
				default: false
			},
			loading: {
				type: Boolean,
				default: false
			}
		},
		emits: ["update:modelValue", "itemSelected"],
		data(): DropDownData {
			let selectedIndex = -1;
			if (this.modelValue) {
				selectedIndex = this.items.indexOf(this.modelValue);
			}
			return {
				localization: LocalizationManager,
				itemsFiltered: this.items,
				itemsVisible: false,
				selectedIndex,
				searchExpression: undefined,
				searchTimeout: -1,
				bodyClickBound: this.bodyClick.bind(this)
			}
		},	
		computed: {
			shared(): Shared {
				return this.localization.languageData.shared;
			},
			selectedItem(): any | undefined {
				if (this.selectedIndex > -1 && this.selectedIndex < this.items.length) {
					return this.items[this.selectedIndex];
				}
				return undefined;
			}
		},
		watch: {
			modelValue(n: any) {
				if (n !== this.selectedItem) {
					this.selectedIndex = this.items.indexOf(n);
				}
			},
			items() {
				this.itemsFilter();
			}
		},
		methods: {
			itemsFilter() {
				if (this.searchFunction !== undefined && this.searchExpression !== undefined && this.searchExpression.length > 0) {
					this.itemsFiltered = this.items.filter(i => this.searchFunction!(i, this.searchExpression!));
				} else {
					this.itemsFiltered = this.items;
				}		
			},
			itemsToggle() {
				if (!this.loading) {
					this.itemsVisible = !this.itemsVisible;
					if (this.itemsVisible) {
						document.body.addEventListener("click", this.bodyClickBound);
					} else {
						document.body.removeEventListener("click", this.bodyClickBound);
					}
				}				
			},
			itemSelect(item: any, index: number) {
				this.selectedIndex = index;
				this.$emit("update:modelValue", item);
				this.$emit("itemSelected", item);
				this.itemsToggle();
			},
			bodyClick() {
				this.itemsToggle();
			},
			bodyClickStop(e: MouseEvent) {
				e.stopPropagation();
			},
			searchKeyUp() {
				clearTimeout(this.searchTimeout);
				this.searchTimeout = setTimeout(() => this.itemsFilter(), 250);
			}			
		}
	});

	export default DropDown;
