import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lang-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drop_down = _resolveComponent("drop-down")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_drop_down, {
      "model-value": _ctx.localization.language,
      items: _ctx.settings.languages,
      onItemSelected: _ctx.languageChange
    }, {
      "selected-item": _withCtx(({ item }: { item: LanguageSetting }) => [
        _createTextVNode(_toDisplayString(item.key.toUpperCase()), 1)
      ]),
      "list-item": _withCtx(({ item }: { item: LanguageSetting }) => [
        _createTextVNode(_toDisplayString(item.key.toUpperCase()), 1)
      ]),
      _: 1
    }, 8, ["model-value", "items", "onItemSelected"])
  ]))
}