const rgxDate = new RegExp(/\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\dZ/);

export function copyPropertiesFrom(target: any, source: any, exclude?: string[]) {
	for (const prop in source) {
		if (!exclude || exclude.indexOf(prop) === -1) {
			//AUTO PARSE FIELDS INTENDED AS DATES
			if (source[prop] === null) {
				target[prop] = undefined;
			} else if (typeof(source[prop]) === "string" && rgxDate.test(source[prop])) {
				target[prop] = new Date(source[prop]);
			} else {
				target[prop] = source[prop];
			}			
		}		
	}
}

export function typify<T = any>(obj: any) {
	const target = { };
	copyPropertiesFrom(target, obj);
	return target as T;
}