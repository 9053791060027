import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "svg" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", null, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("use", {
        href: '#' + _ctx.iconName
      }, null, 8, _hoisted_2)
    ]))
  ]))
}