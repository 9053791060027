
	import Settings from "@/settings";
	import { DialogManager, DialogState } from "@/state/DialogManager";
	import { defineComponent } from "vue";
	import DialogWindow from "./DialogWindow.vue";
	import LocalizationManager from "@/localization/LocalizationManager";
	import { Shared } from "@/localization/LanguageData";

	export const DialogBase = defineComponent({
		props: {
			uniqueID: {
				type: Number,
				required: true
			}
		},
		components: { DialogWindow },
		data() {
			return {
				dialogs: DialogManager,
				settings: Settings,
				localization: LocalizationManager,
				definition: DialogManager.definitionByUniqueID(this.uniqueID)!,
				isReady: false
			}
		},
		created() {
			setTimeout(() => {
				this.definition.state = DialogState.Open;
			});
		},
		computed: {
			shared(): Shared {
				return this.localization?.languageData.shared;
			}
		},
		methods: {
			dialogDataGet<T>(): T {
				//THIS METHOD MAY BE CALLED BEFORE DATA IS GENERATED
				return DialogManager.definitionByUniqueID(this.uniqueID)!.dialogData as T;
			},

			returnOk(data?: unknown) {
				if (this.definition?.dialogOptions.ok) {
					this.definition.dialogOptions.ok(data);
				}
				this.close();
			},

			returnCancel(data?: unknown) {
				if (this.definition?.dialogOptions.cancel) {
					this.definition.dialogOptions.cancel(data);
				}
				this.close();
			},
			wait() {
				this.definition.state = DialogState.Waiting;
			},
			go() {
				this.definition.state = DialogState.Open;
			},
			close() {
				this.definition.state = DialogState.Closing;				
				setTimeout(() => {
					this.dialogs.remove(this.definition.uniqueID);		
				}, 500);
			}
		}
	});
	export default DialogBase;
