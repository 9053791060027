import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-pair" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "input-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createBlock(_component_dialog_window, {
    definition: _ctx.definition,
    "min-width": "200px"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.dialogDataTyped.title), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "input-cell-label",
          innerHTML: _ctx.dialogDataTyped.message
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: "number",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
          }, null, 512), [
            [
              _vModelText,
              _ctx.value,
              void 0,
              { number: true }
            ]
          ])
        ])
      ])
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("a", {
        class: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.ok();})
      }, "Ok"),
      _createElementVNode("a", {
        class: "button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.cancel();})
      }, _toDisplayString(_ctx.shared.cancel), 1)
    ]),
    _: 1
  }, 8, ["definition"]))
}