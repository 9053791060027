
    import IconInline from "@/components/icon-library/IconInline.vue";
    import IconLibrary from "@/components/icon-library/IconLibrary.vue";
    import DropDown from "@/components/controls/drop-down/DropDown.vue";
    import LocalInput from "@/components/controls/local-input/LocalInput.vue";
    import Auth, { AuthContext, RoleNames } from "@/security/Auth";
    import { defineComponent } from "vue";
    import WaitingScreen from "./components/waiting/WaitingScreen.vue";
    import Settings, { SettingsContext } from "./settings";
    import ConfigurationManager, { ConfigurationManagerContext } from "./state/ConfigurationManager";
    import DialogManager, { DialogManagerContext } from "./state/DialogManager";
    import { SecureFetch } from "./util/ajax";
    import DateUtilities from "./util/dates";
    import LocalizationManager, { LocalizationManagerContext } from "./localization/LocalizationManager";
    import { LanguageData } from "./localization/LanguageData";
    import LangSelector from "./components/controls/lang-selector/LangSelector.vue";

    interface AppMainData {
        isReady: boolean;
        settings: SettingsContext,
        localization: LocalizationManagerContext,
        auth: AuthContext;
        dialogs: DialogManagerContext;      
        config: ConfigurationManagerContext;
        activityTimeout: number;
        lastUpdate: string;
    }

    const AppMain = defineComponent({
        components: {
            IconLibrary,
            IconInline,
            DropDown,
            LangSelector,
            LocalInput,
            WaitingScreen
        },
        data(): AppMainData {
            return {          
                isReady: false,
                settings: Settings,
                localization: LocalizationManager,
                auth: Auth,
                dialogs: DialogManager,
                config: ConfigurationManager,
                activityTimeout: -1,                
                lastUpdate: DateUtilities.localDateString(Settings.lastUpdate)
            };
        },        
        async created() {  
            await this.settings.initialize();

            await this.localization.configure();

            const isAuthenticated = await this.auth.configure({
                rootEndPoint: this.settings.apiRoot + "Membership/",
                cookieName: this.settings.authCookieName,
                afterLogin: () => { this.redirectToDefault(); },
                afterLogout: () => { this.redirectToLogin(); },
                afterRequireLogin: () => { this.redirectToLogin(); },                
                onError: (error) => { console.log(error); }
            });
           
            //PERFORM THE REQUIRED REDIRECTION
            if (isAuthenticated) {
                this.redirectToDefault();
            } else {
                this.redirectToLogin();
            }

            SecureFetch.configure({
                onUnauthorized: () => { 
                    if (this.auth.isAuthenticated) {
                        this.dialogs.message("Access Denied", "You do not have enough permissions to perform this action.");
                        this.redirectToDefault();
                    } else {
                        this.redirectToLogin();
                    }
                },
                onError: (errorMessage) => {
                    this.dialogs.message("Exception", errorMessage);
                }
            });
            
            this.isReady = true;

            await this.auth.waitForAuth();
            await this.config.waitForConfigurations();
            
            //BIND ACTIVITY SIGNAL EVENTS
            document.addEventListener("click", this.activity.bind(this));
            document.addEventListener("mousemove", this.activity.bind(this));
            document.addEventListener("scroll", this.activity.bind(this));
            document.addEventListener("touchstart", this.activity.bind(this));

            this.activity();            
        },
        computed: {
            l(): LanguageData {
                return this.localization?.languageData;
            },
            showAdministration(): boolean {
                return this.auth?.checkAuthorization(RoleNames.Developers, RoleNames.Administrators);
            },
            showEmployee(): boolean {
                return this.auth?.checkAuthorization(RoleNames.Employees);
            },
            showManagement(): boolean {
                return this.auth?.checkAuthorization(RoleNames.Developers, RoleNames.Administrators, RoleNames.Payroll, RoleNames.Managers);
            },
            showPayroll(): boolean {
                return this.auth?.checkAuthorization(RoleNames.Developers, RoleNames.Administrators, RoleNames.Payroll);
            },
            showMembership(): boolean {
                return this.auth?.checkAuthorization(RoleNames.Developers, RoleNames.Administrators, RoleNames.Payroll);
            },   
            showBackgroundTasks(): boolean {
                return this.auth?.checkAuthorization(RoleNames.Developers, RoleNames.Administrators, RoleNames.Payroll);
            }
        },
        methods: {
            activity() {
                clearTimeout(this.activityTimeout);
                if (this.config.configGeneral) {
                    this.activityTimeout = setTimeout(this.activityTimeoutElapsed.bind(this), this.config.configGeneral.inactivityLogOutSeconds * 1000);
                }      
            },
            activityTimeoutElapsed() {
                console.log("Inactivity timeout elapsed.")
                if (this.auth.isAuthenticated && !this.auth.checkAuthorization(RoleNames.Developers, RoleNames.Administrators, RoleNames.Managers, RoleNames.Payroll)) {
                    this.auth.logout();
                }
                if (this.config.configGeneral) {
                    this.activityTimeout = setTimeout(this.activityTimeoutElapsed.bind(this), this.config.configGeneral.inactivityLogOutSeconds * 1000);
                }
            },
            redirectToLogin() {
                this.dialogs.clear();
                this.$router.replace({                    
                    name: "login"
                })
            },
            redirectToDefault() {
                console.log("Logged in");
                if (this.$route.name === "default" || this.$route.name === "login") {
                    if (this.showEmployee) {
                        this.$router.replace({ name: "employee" });
                    } else if (this.showManagement) {
                        this.$router.replace({ name: "management-default" });
                    } else if (this.showPayroll) {
                        this.$router.replace({ name: "payroll-default" });
                    } else {
                        this.dialogs.message("Access Denied", "At least one role is required.").then(() => {
                            this.auth.logout();
                            window.location.reload();
                        });
                    }
                }                
            },
            logout() {
                this.auth.logout();
            }
        }
    })
    export default AppMain;
