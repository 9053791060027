import { LanguageSetting } from "./localization/LanguageSetting";
import { Fetch } from "./util/ajax";

export class SettingsContext {
    private static instance: SettingsContext;

    public static instantiate() {
        if (!this.instance) {
            this.instance = new SettingsContext();
        }
        return this.instance;
    }

    apiRoot: string;
    appVersion: string;
    requireSSL: boolean;
    authCookieName: string;
    langCookieName: string;
    lastUpdate: Date;

    languages: LanguageSetting[];
    defaultLanguage: LanguageSetting;
    
    constructor() {
        this.appVersion = process.env.VUE_APP_VERSION!;
        this.requireSSL = (process.env.VUE_APP_REQUIRE_SSL === "1");
        this.authCookieName = process.env.VUE_APP_AUTH_COOKIE_NAME!;
        this.langCookieName = process.env.VUE_APP_LANG_COOKIE_NAME!;
        this.lastUpdate = new Date(process.env.VUE_APP_LAST_UPDATE);
    }

    async initialize() {
        const r = await Fetch.get("/appsettings.json").then(r => r.json());
        
        this.apiRoot = r.apiRoot;
        this.languages = r.languages;

        if (!this.languages || this.languages.length == 0) {
            this.languages = [
                {
                    name: "English",
                    key: "en",
                    default: true
                }
            ]
        }
    }
}

const Settings = SettingsContext.instantiate();
export default Settings;