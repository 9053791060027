export interface LogicConditionContainer {
	conditions: LogicCondition[];
	booleanOperator: LogicBooleanOperator;
}

export interface LogicBlock extends LogicConditionContainer {	
	blocks: LogicBlock[];
	return?: string;
}

export interface LogicCondition extends LogicConditionContainer {
	expressionA?: string;
	expressionB?: string;
	relationalOperator: LogicRelationalOperator;
}

export interface LogicExpressionItem {
	expression: string;
	type: LogicExpressionType;
	valuesQuery?: (item: LogicExpressionItem, expression: string) => Promise<string[]>;
}

export enum LogicBooleanOperator {
	AND = 0,
	OR = 10
}

export enum LogicExpressionType {
	Boolean = 0,
	String = 1,
	Number = 2,
	DateTime = 3,
	ArrayOfString = 101,
	ArrayOfNumber = 201,
	ArrayOfDateTime = 301
}

export enum LogicRelationalOperator {
	LessThan = 0,
	GreaterThan = 10,
	LessThanOrEqualTo = 20,
    GreaterThanOrEqualTo = 30,
    EqualTo = 40,
    NotEqualTo = 50,
    Contains = 60,
    DoesNotContain = 70,
	AnyItemContains = 80,
	NoItemContains = 90
}