
    import { defineComponent } from "vue";

    interface TimeSpanEditorData {
        h?: number;
        m?: number;
        s?: number;
        ms?: number; 
    }

    const TimeSpanEditor = defineComponent({
        props: {
            modelValue: {
                type: Number,
                required: false
            },
            enableUndefined: {
                type: Boolean,
                default: true
            },
            showSeconds: {
                type: Boolean,
                default: false
            },
            showMilliseconds: {
                type: Boolean,
                default: false
            }
        },
        data(): TimeSpanEditorData {
            return {
                h: undefined,
                m: undefined,
                s: undefined,
                ms: undefined
            };
        },
        created() {
            this.timeSpanLoad();
        },
        computed: {
            msTotal() {
                if (!this.hasValue(this.h) || !this.hasValue(this.m) || (this.showSeconds && !this.hasValue(this.s)) || (this.showMilliseconds && !this.hasValue(this.ms))) {
                    return undefined;
                }
                return Math.round(((this.h ?? 0) * 3600000) + ((this.m ?? 0) * 60000) + ((this.s ?? 0) * 1000) + (this.ms ?? 0));
            }
        },
        methods: {
            timeSpanLoad() {
                if (this.modelValue === undefined) {
                    this.h = undefined;
                    this.m = undefined;
                    this.s = undefined;
                    this.ms = undefined;
                } else {
                    let rem = this.modelValue;

                    this.h = Math.floor(rem / 3600000);
                    rem = rem % 3600000;

                    this.m = Math.floor(rem / 60000);
                    rem = rem % 60000;

                    this.s = Math.floor(rem / 1000);
                    this.ms = rem % 1000;
                }
            },
            timeSpanChanged() {                
                this.$emit("update:modelValue", this.msTotal);
            },
            hasValue(v: number | undefined) {
                return v !== undefined && !isNaN(v!) && v.toString().trim().length > 0;
            }
        },
        watch: {
            modelValue() {
                this.timeSpanLoad();
            }
        }
    });
    export default TimeSpanEditor;
