class Cookie {
    Key: string;
    Value: string;

    constructor(pair: string[]) {
        this.Key = pair[0].trim();
        this.Value = pair[1].trim();
    }
}

export class CookiesContext {
    private static instance: CookiesContext;
    
    public cookies: Cookie[];

    public static init() {
        if (!CookiesContext.instance) {
            CookiesContext.instance = new CookiesContext();
        }
        return CookiesContext.instance;
    }

    private constructor() {
        this.cookies = this.cookiesGet();
    }
    public cookiesGet(): Cookie[] {
        return document.cookie.split(";").map(p => p.split("=")).filter(p => p.length == 2).map(p => new Cookie(p));
    }

    public cookieGet(key: string): Cookie | undefined {
        return this.cookies.find(c => c.Key === key);
    }

    public cookieSet(key: string, value: string, expires: Date) {
        document.cookie = `${key}=${value};expires=${expires.toString()};path=/`;
    }

    public cookieDelete(key: string) {
        const date = new Date();
        date.setSeconds(-1);
        document.cookie = `${key}=;expires=${date.toUTCString()};path=/`;
    }
}

const Cookies = CookiesContext.init();
export default Cookies;