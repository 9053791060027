import { registerValidator, Validatable } from "./Validatable";
import { ValidatorBase } from "./ValidatorBase";

export function custom<T extends Validatable = Validatable, P = any>(fn: CustomValidationFunction<T, P>) {
    return function(target: T, propertyKey: string) {
        registerValidator<T>(target as T, () => new ValidatorCustom("custom", propertyKey.toString(), fn));
    }
} 

export interface CustomValidationResult {
    isValid: boolean;
    errorMessage: string;
}

export type CustomValidationFunction<T = any, P = any> = (instance: T, propertyValue: P) => Promise<CustomValidationResult>;

export class ValidatorCustom<T = Validatable> extends ValidatorBase<T> {
    customValidationFunction: CustomValidationFunction;
    customErrorMessage = "";

    constructor(validatorName: string, propertyKey: string, customValidationFunction: CustomValidationFunction) {
        super(validatorName, propertyKey, () => this.customErrorMessage);
        this.customValidationFunction = customValidationFunction;
    }

    async validate(instance: T) {
        const result = await this.customValidationFunction(instance, (instance as any)[this.propertyKey]);
        this.isValid = result.isValid;
        this.customErrorMessage = result.errorMessage;
        return this.isValid;
    }
}