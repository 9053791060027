import { createApp } from "vue"
import App from './App.vue'
import router from './router'

import LogicDesignerBlock from "@/components/logic-designer/LogicDesignerBlock.vue";
import LogicDesignerConditionContainer from "@/components/logic-designer/LogicDesignerConditionContainer.vue";
import LogicDesignerConditionLeadIn from "@/components/logic-designer/LogicDesignerConditionLeadIn.vue";

import DatePicker from '@vuepic/vue-datepicker';
import "@/styles/date-picker/main.scss"

import "@/styles/layout.css";
import "@/styles/forms.css";
import "@/styles/grid.css";
import "@/styles/dialog.css";

const app = createApp(App);

app.component("LogicDesignerBlock", LogicDesignerBlock)
   .component("LogicDesignerConditionContainer", LogicDesignerConditionContainer)
   .component("LogicDesignerConditionLeadIn", LogicDesignerConditionLeadIn);

app.component("DatePicker", DatePicker);

app.use(router);
app.mount('#app');