
	import { defineComponent } from "vue";
	import DialogBase from "../dialog-base/DialogBase.vue";
	import QrScanner from "qr-scanner";

	interface ScannerDialogData {
		title: string;
		showTitle: boolean;
	}

	interface ScannerData {
		dialogDataTyped: ScannerDialogData;
		cameras?: ScannerCamera[];
		selectedCameraID?: string;
		scanner?: QrScanner;
		errorMessage?: string;
	}

	interface ScannerCamera {
		id: string,
		label: string
	}

	export default defineComponent({
		extends: DialogBase,		
		data(): ScannerData {
			const dialogDataTyped = this.dialogDataGet<ScannerDialogData>();
			return {
				dialogDataTyped,
				cameras: undefined,
				selectedCameraID: undefined,
				scanner: undefined,
				errorMessage: undefined
			}
		},
		mounted() {
			this.initialize();
		},
		watch: {
			selectedCameraID(id: string | undefined) {
				console.log(`Camera changed to ${id}`)
				if (id !== undefined && this.scanner) {
					this.scanner.$video.play().then(() => {
						this.scanner!.setCamera(id);
					});					
				}
			}
		},
		methods: {
			async initialize() {
				console.log("Scanner Initializing");

				this.cameras = await QrScanner.listCameras(true).then(l => l.map(c => ({ ...c })));

				if (this.cameras && this.cameras.length > 0) {
					this.selectedCameraID = this.cameras[0].id;
				} else {
					this.errorMessage = "Camera not found or permission not granted.";
				}

				this.scanner = new QrScanner(
					document.getElementById("vidScanner") as HTMLVideoElement,
					(r) => this.codeScanned(r), 
					{ 
						returnDetailedScanResult: true,
						highlightScanRegion: true,
						highlightCodeOutline: true
					}					
				);

				this.scanner.start();
			},
			codeScanned(r: QrScanner.ScanResult) {
				if (r.data) {
					console.log(r);
					this.scanner!.stop();
					this.scanner!.destroy();
					this.returnOk(r.data);
				}
			},
			cancel() {
				this.returnCancel();
			}
		}
		
	});
