import { Validatable } from "@/validation/Validatable";
import { custom } from "@/validation/ValidatorCustom";

export default class LoginModel extends Validatable {	
	@custom<LoginModel, number>(async (t) => ({ isValid : (t.key ?? "").length > 0 || (t.userName ?? "").length > 0, errorMessage: "User Name is required." }))
	userName?: string;

	@custom<LoginModel, number>(async (t) => ({ isValid : (t.key ?? "").length > 0 || (t.password ?? "").length > 0, errorMessage: "Password is required." }))
	password?: string;

	key?: string;
}