import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["placeholder"]
const _hoisted_2 = {
  class: "pos-ref",
  ref: "divPosRef"
}
const _hoisted_3 = ["data-value", "innerHTML", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-cell auto-complete", _ctx.cssClassObject])
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expression) = $event)),
      placeholder: _ctx.placeholder,
      onKeyup: [
        _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.expressionInput && _ctx.expressionInput(...args))),
        _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.itemSelect && _ctx.itemSelect(...args)), ["enter"])),
        _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.itemSelect && _ctx.itemSelect(...args)), ["tab"])),
        _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.goDown && _ctx.goDown(...args)), ["down"])),
        _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.goUp && _ctx.goUp(...args)), ["up"]))
      ],
      onBlur: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
      onFocus: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
      ref: "txtExpression"
    }, null, 40, _hoisted_1), [
      [_vModelText, _ctx.expression]
    ]),
    _createElementVNode("div", _hoisted_2, null, 512),
    ((_ctx.items.length > 0 || _ctx.enableNoResultItem) && _ctx.itemsVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "auto-complete-items",
          style: _normalizeStyle({ left: `${_ctx.itemsX}px`, top: `${_ctx.itemsY}px`, minWidth: `${_ctx.itemsMinWidth}px` }),
          ref: "divAutoCompleteItems"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("a", {
              class: _normalizeClass({ 'active' : _ctx.selectedIndex === index }),
              "data-value": _ctx.valueExpression(item),
              innerHTML: _ctx.localize(_ctx.htmlExpression ? _ctx.htmlExpression(item) : _ctx.valueExpression(item)),
              onClick: ($event: any) => (_ctx.itemSelectIndex(index)),
              key: index,
              ref_for: true,
              ref: 'i-' + index.toString()
            }, null, 10, _hoisted_3))
          }), 128)),
          (_ctx.items.length === 0 && _ctx.enableNoResultItem)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "active",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.itemSelect()))
              }, [
                _renderSlot(_ctx.$slots, "no-result-item", { expression: _ctx.expression })
              ]))
            : _createCommentVNode("", true)
        ], 4))
      : _createCommentVNode("", true)
  ], 2))
}