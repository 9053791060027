import { Component, markRaw } from "vue";
import DialogMessage from "@/components/dialog-base/DialogMessage.vue";
import DialogConfirm from "@/components/dialog-base/DialogConfirm.vue";
import DialogPromptString from "@/components/dialog-base/DialogPromptString.vue";
import DialogPromptNumeric from "@/components/dialog-base/DialogPromptNumeric.vue";
import DialogPromptTimeSpan from "@/components/dialog-base/DialogPromptTimeSpan.vue";
import DialogPromptDateTime from "@/components/dialog-base/DialogPromptDateTime.vue";

export interface DialogDefinition {
    uniqueID: number;
    component: Component;
    dialogOptions: DialogOptions;
    state: DialogState;
    dialogData?: unknown;
}

export enum DialogState {
    Opening = 10,
    Open = 20,
    Closing = 30,
    Waiting = 40
} 

export interface DialogOptions<T = any> {
    ok?: (data: T) => void;
    cancel?: (data: T) => void;
    stateChanged?: (state: DialogState) => void;
}

export class DialogManagerContext {
	private static instance: DialogManagerContext;
   
    public static instantiate() {
        if (!this.instance) {
            this.instance = new DialogManagerContext();
        }
        return this.instance;
    }

    public dialogs: DialogDefinition[] = [];

	public add(component: Component, dialogOptions: DialogOptions, dialogData?: unknown) {
        const raw = markRaw(component);
        const uniqueID = this.dialogs.length > 0 ? Math.max(...this.dialogs.map(d => d.uniqueID)) + 1 : 1;
        const definition: DialogDefinition = {
            uniqueID,
            component: raw,
            dialogOptions: dialogOptions,
            state: DialogState.Opening,
            dialogData: dialogData
        };

        this.dialogs.push(definition);
    }

    public remove(uniqueID: number) {
        const dialogIndex = this.dialogs.findIndex(d => d.uniqueID === uniqueID);
        this.dialogs.splice(dialogIndex, 1);
    }

    public clear() {
        this.dialogs.splice(0, this.dialogs.length);
    }

    public definitionByUniqueID(uniqueID: number) {
        return this.dialogs.find(d => d.uniqueID === uniqueID);
    }
    
    public message(title: string, message: string) {
        return new Promise<boolean>((resolve) => {
            this.add(DialogMessage, { 
                ok: () => resolve(true)
            }, { title, message });
        });
    }

    public promptString(title: string, message: string, defaultValue = "") {
        return new Promise<string>((resolve) => {
            this.add(DialogPromptString, { 
                ok: (data: string) => resolve(data)
            }, { title, message, defaultValue });
        });
    }

    public promptNumeric(title: string, message: string, defaultValue: number) {
        return new Promise<number>((resolve) => {
            this.add(DialogPromptNumeric, { 
                ok: (data: number) => resolve(data)
            }, { title, message, defaultValue });
        });
    }

    public promptTimeSpan(title: string, message: string, defaultValue: number) {
        return new Promise<number>((resolve) => {
            this.add(DialogPromptTimeSpan, { 
                ok: (data: number) => resolve(data)
            }, { title, message, defaultValue });
        });
    }

    public promptDateTime(title: string, message: string, defaultValue: Date) {
        return new Promise<Date>((resolve) => {
            this.add(DialogPromptDateTime, { 
                ok: (data: Date) => resolve(data)
            }, { title, message, defaultValue });
        });
    }

    public confirm(title: string, message: string) {
        return new Promise<boolean>((resolve) => {
            this.add(DialogConfirm, { 
                ok: () => resolve(true),
                cancel: () => resolve(false)
            }, { title, message });
        });
    }

    public waiting = false;
    public waitingInvisible = false;

    public wait(invisible = false) {
        if (invisible) {
            this.waitingInvisible = true;
        } else {
            this.waiting = true;
        }        
    }

    public go() {
        this.waiting = false;
        this.waitingInvisible = false;
    }
}

export const DialogManager = DialogManagerContext.instantiate();
export default DialogManager;