import { ValidatorBase } from "./ValidatorBase";

export abstract class Validatable {
    public static validatorMappings: ValidatorMapping[] = [];

    public validators: ValidatorBase[] = [];    
        
    public isValid = true;
    public validationErrors: string[] = [];

    constructor() {        
        this.isValid = true;
        const prototype = (Object.getPrototypeOf(this) as Validatable);        
        const mapping = Validatable.validatorMappings.find(m => m.prototype === prototype);
        if (mapping) {
            this.validators.push(...mapping.factories.map(f => f())); 
        }
    }

    public async validate() {
        this.isValid = true;
        this.validationErrors.splice(0, this.validationErrors.length);   

        const children: Validatable[] = [];
        for (const p in this) {
            if (this[p] instanceof Validatable) {
                const child = this[p] as unknown as Validatable;
                children.push(child);
            } else if (Array.isArray(this[p])) {
                const array = this[p] as unknown as any[];
                if (array.length > 0 && array[0] instanceof Validatable) {
                    children.push(...array as Validatable[]);
                }
            }
        }

        await Promise.all([
            Promise.all(this.validators.map(v => v.validate(this))),
            Promise.all(children.map(c => c.validate()))
        ]);

        this.validators.forEach(v => {
            if (!v.isValid) {
                this.isValid = false;
                if (v.errorMessage) {
                    this.validationErrorsAdd(v.errorMessage(this))
                }
            }
        });

        children.forEach(c => {
            if (!c.isValid) {
                this.isValid = false;
                this.validationErrorsAdd(...c.validationErrors);
            }
        });

        return this.isValid;
    }

    public validationErrorsAdd(...errors: string[]) {
        for (let e = 0; e < errors.length; e++) {
            if (this.validationErrors.indexOf(errors[e])) {
                this.validationErrors.push(errors[e]);
            }
        }
    }
}

export interface ValidatorMapping {
    prototype: Validatable;
    factories: ValidatorFactory[];
}

export type ValidatorFactory<T extends Validatable = Validatable> = () => ValidatorBase<T>;

export function registerValidator<T extends Validatable = Validatable>(target: T, validatorFactory: ValidatorFactory<T>)  {
    let mapping = Validatable.validatorMappings.find(m => m.prototype === target);
    if (!mapping) {
        mapping = {
            prototype: target,
            factories: []
        };
        Validatable.validatorMappings.push(mapping);
    }
    mapping.factories.push(validatorFactory as ValidatorFactory);
}