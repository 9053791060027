import { SimpleAction } from "@/util/types";

interface CallbackGroup {
    key: string;
    callbacks: SimpleAction[];
} 

export class CallbacksContext {
    private static instance: CallbacksContext;
   
    public static instantiate() {
        if (!this.instance) {
            this.instance = new CallbacksContext();
        }
        return this.instance;
    }

    public callbackGroups: CallbackGroup[] = [];

    public register(key: string, ...actions: SimpleAction[]) {
        let group = this.callbackGroups.find(c => c.key === key);
        if (group) {
            group.callbacks.push(...actions);
        } else {
            group = {
                key: key,
                callbacks: actions
            }
            this.callbackGroups.push(group);
        }
    }

    public callback(key: string) {
        const i = this.callbackGroups.findIndex(c => c.key === key);
        if (i > -1) {
            const group = this.callbackGroups.splice(i, 1)[0];
            group.callbacks.forEach(c => c());
        }
    }
}

const Callbacks = CallbacksContext.instantiate();
export default Callbacks;