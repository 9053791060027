
	import { defineComponent, PropType } from "vue";
	import { LogicConditionContainer, LogicCondition, LogicExpressionItem , LogicExpressionType } from "./LogicModel";
	import AutoComplete, { AutoCompleteQueryData } from "../controls/auto-complete/AutoComplete.vue";
	
	export default defineComponent({
		props: {
			model: {
				type: Object as PropType<LogicCondition>,
				required: true
			},
			parent: {
				type: Object as PropType<LogicConditionContainer>,
				required: true
			},
			ordinal: {
				type: Number,
				required: true
			},
			expressionItems: {
				type: Array as PropType<LogicExpressionItem[]>,
				required: false
			}
		},
		emits: ["conditionAdd", "conditionRemove"],
		components: {
			AutoComplete
		},
		data() {
			return {
				condition: this.model,
				container: this.parent
			}
		},
		computed: {
			expressionItem() : LogicExpressionItem | undefined {
				return this.expressionItems?.find(e => e.expression === this.condition.expressionA);
			},
			allowCompare() {
				return !this.expressionItem || this.expressionItem.type === LogicExpressionType.Number || this.expressionItem.type === LogicExpressionType.DateTime;
			},
			allowEqual() {
				return this.allowCompare || this.expressionItem!.type === LogicExpressionType.String || this.expressionItem!.type === LogicExpressionType.Boolean;
			},
			allowContains() {
				return !this.expressionItem || this.expressionItem.type === LogicExpressionType.String;
			},
			allowArrayContains() {
				return !this.expressionItem || this.expressionItem.type === LogicExpressionType.ArrayOfString;
			}
		},
		methods: {
			async query(d: AutoCompleteQueryData) {
				const expressionLowered = d.expression.toLowerCase();
				return this.expressionItems!.filter(i => i.expression.toLowerCase().indexOf(expressionLowered) > -1);
			},
			async valuesQuery(d: AutoCompleteQueryData) {
				return await this.expressionItem!.valuesQuery!(this.expressionItem!, d.expression);
			},
			conditionAddSub(condition: LogicConditionContainer) {	
				this.$emit("conditionAdd", condition);				
			},
			conditionRemove(condition: LogicCondition) {
				this.$emit("conditionRemove", condition);	
			}
		}
	});
