import DateUtilities from "./dates";

export default class UrlUtilities {
    // public static objectToQuery(...objects: any[]) {
    //     let query = "";

    //     objects.forEach(o => {
    //         for (const key in o as any) {
    //             if (Object.prototype.hasOwnProperty.call(o, key)) {
    //                 if (o[key] === undefined || o[key] === null) {
    //                     continue;
    //                 }

    //                 if (query.length === 0) {
    //                     query += "?";
    //                 } else {
    //                     query += "&";
    //                 }
    //                 query += `${encodeURIComponent(key)}=${encodeURIComponent(o[key])}`;
    //             }
    //         }
    //     });

    //     return query;
    // }

    public static objectToQuery(data: any, prefix = "", start = true) {
        let result = "";
        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                result += this.objectToQuery(data[i], prefix + `[${i}]`, false);
            }
        } else if (data instanceof Date) {
            result += "&" + `${encodeURIComponent(prefix)}=${encodeURIComponent(DateUtilities.utcDateTimeParsableStringFromDate(data))}`;
            start = false;
        } else if (typeof(data) === "object" && data !== null) {
            for (const prop in data) {
                result += this.objectToQuery(data[prop], prefix.length > 0 ? `${prefix}.${prop}` : prop, false);
            }
        } else if (typeof(data) !== "undefined") {
            result += "&" + `${encodeURIComponent(prefix)}=${encodeURIComponent(data)}`;
        }
        if (start && result.length > 0) {
            return "?" + result.substring(1);
        }
        return result;
    }
}