import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "drop-down-content" }
const _hoisted_2 = {
  key: 0,
  class: "input-cell drop-down-search"
}
const _hoisted_3 = { class: "drop-down-items" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["drop-down", { 'active' : _ctx.itemsVisible && _ctx.items && _ctx.items.length > 0, 'wider' : _ctx.wider }]),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.bodyClickStop && _ctx.bodyClickStop(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "drop-down-item-selected",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.itemsToggle && _ctx.itemsToggle(...args)))
      }, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.shared.loading), 1)
            ], 64))
          : _renderSlot(_ctx.$slots, "selected-item", {
              key: 1,
              item: _ctx.selectedItem
            })
      ]),
      (_ctx.searchFunction)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchExpression) = $event)),
              type: "text",
              placeholder: "Search",
              onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.searchKeyUp && _ctx.searchKeyUp(...args)))
            }, null, 544), [
              [_vModelText, _ctx.searchExpression]
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsFiltered, (item, i) => {
          return (_openBlock(), _createElementBlock("a", {
            class: _normalizeClass({ 'active' : _ctx.selectedItem === item }),
            onClick: ($event: any) => (_ctx.itemSelect(item, i)),
            key: i
          }, [
            _renderSlot(_ctx.$slots, "list-item", { item: item })
          ], 10, _hoisted_4))
        }), 128))
      ])
    ])
  ], 2))
}