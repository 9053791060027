import ConfigGeneralModel from "@/models/configuration/ConfigGeneralModel";
import Settings from "@/settings";
import { SecureFetch } from "@/util/ajax";
import Callbacks from "@/util/callbacks";

export class ConfigurationManagerContext {
    private static instance: ConfigurationManagerContext;
   
    public static instantiate() {
        if (!this.instance) {
            this.instance = new ConfigurationManagerContext();
        }
        return this.instance;
    }

    private fetching = false;

    public configGeneral?: ConfigGeneralModel;
  
    waitForConfigurations() {
        return new Promise<ConfigGeneralModel>((resolve) => {
            if (this.configGeneral) {
                resolve(this.configGeneral!);
            } else {
                Callbacks.register("config", () => resolve(this.configGeneral!));                
                if (!this.fetching) {
                    this.fetching = true;
                    SecureFetch.get(Settings.apiRoot + "Configuration/ConfigGeneral")
                        .then(r => {
                            this.configGeneral = new ConfigGeneralModel(r);                            
                            Callbacks.callback("config");
                            this.fetching = false;
                        }); 
                } 
            }
        });        
    }
}

export const ConfigurationManager = ConfigurationManagerContext.instantiate();
export default ConfigurationManager;