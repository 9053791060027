
    import { SecureFetch } from "@/util/ajax";
    import { defineComponent, PropType } from "vue";
    import { Dynamic } from "@/types/GenericTypes";
    import LocalizationManager, { LocalizationManagerContext } from "@/localization/LocalizationManager";
    import Settings, { SettingsContext } from "@/settings";
    import { LanguageSetting } from "@/localization/LanguageSetting";

    interface AutoCompleteData {
        settings: SettingsContext;
        localization: LocalizationManagerContext;
        itemsVisible: boolean;
        itemsX: number;
        itemsY: number;
        itemsMinWidth: number;
        expression: string;
        previousExpression: string;
        expressionChangeTimeout: number;       
        items: Dynamic<string>[];
        selectedIndex: number;
    }

    export interface AutoCompleteQueryData<T = any> {
        expression: string;
        payLoad?: T;
    }

    export default defineComponent({
        props: {
            endPoint: {
                type: String,
                required: false
            },
            query: {
                type: Function as PropType<(d: AutoCompleteQueryData) => Promise<any[]>>
            },
            payload: {
                type: Object as any,
                required: false
            },
            modelValue: {
                required: false
            },
            display: {
                type: String,
                required: false
            },
            showOnFocus: {
                type: Boolean,
                default: false
            },
            valueExpression: {
                type: Function as PropType<(o: Dynamic<string>) => any>,
                required: true
            },
            displayExpression: {
                type: Function as PropType<(o: Dynamic<string>) => any>,
                required: true
            },
            htmlExpression: {
                type: Function as PropType<(o: Dynamic<string>) => any>,
                required: false
            },
            minExpressionLength: {
                type: Number,
                default: 3
            },
            placeholder: {
                type: String,
                required: false
            },
            cssClass: {
                type: String,
                required: false
            },
            enableNoResultItem: {
                type: Boolean,
                required: false
            },
            enableLocalization: {
                type: Boolean,
                default: false
            }
        },
        emits: [ "update:modelValue", "update:display", "item-selected", "item-no-result-selected" ],
        data(): AutoCompleteData {
            const localization = LocalizationManager;

            return {          
                settings: Settings, 
                localization,
                itemsVisible: false,
                itemsX: 0,
                itemsY: 0,
                itemsMinWidth: 0,
                expression: "",
                previousExpression: "",
                expressionChangeTimeout: -1,                
                items: [],
                selectedIndex: -1
            }
        },
        created() {
            console.log(this.display);
            console.log(this.expressionFromDisplay(this.display));
            this.expression = this.expressionFromDisplay(this.display);
        },
        computed: {
            language(): LanguageSetting {
                return this.localization.language;
            },
            selectedItem(): Dynamic<string> | undefined {
                return this.items.length > this.selectedIndex && this.selectedIndex > -1 ? this.items[this.selectedIndex] : undefined;
            },
            txtExpression(): HTMLInputElement {
                return this.$refs.txtExpression as HTMLInputElement;
            },
            divPosRef(): HTMLDivElement {
                return this.$refs.divPosRef as HTMLDivElement;
            },
            cssClassObject(): unknown {
                const o: any = {
                    "input-cell": true,
                    "auto-complete": true
                };
                if (this.cssClass) {
                    o[this.cssClass] = true;
                }
                return o;
            }
        },
        watch: {
            display(n: string) {
				this.expression = this.expressionFromDisplay(n); 
            },
            language() {
                this.expression = this.expressionFromDisplay(this.display);
            },
            itemsVisible(n: boolean) {
                if (!n) return;

                const refRect = this.divPosRef.getBoundingClientRect();
                const rect = this.txtExpression.getBoundingClientRect();
                this.itemsX = rect.x - refRect.x;
                this.itemsY = rect.y + rect.height - refRect.y;
                this.itemsMinWidth = rect.width;
            }
        },
        methods: {
            expressionFromDisplay(display: string | undefined): string {
                if (!this.enableLocalization) return display ?? "";
				return this.localization.value(display) ?? ""; 
            },

            async expressionInput() {                
                if (this.expression !== this.previousExpression) {
                    clearTimeout(this.expressionChangeTimeout);                    
                    if (this.expression.length >= this.minExpressionLength) {
                        this.expressionChangeTimeout = setTimeout(async () => {
                            this.previousExpression = this.expression;
                            await this.itemsFetch();
                        }, 300) as unknown as number;
                        this.previousExpression = this.expression;
                    } else if (this.modelValue !== undefined) {                        
                        this.$emit("update:modelValue", undefined);                        
                        this.$emit("item-selected", undefined);
                    }

                    if (this.enableLocalization) {
                        const displayObject = { };
                        this.settings.languages.forEach(l => {
                            displayObject[l.key] = this.expression;
                        });
                        this.$emit("update:display", JSON.stringify(displayObject));
                    } else {
                        this.$emit("update:display", this.expression);
                    }                                    
                }
            },
            async itemsFetch() {
                const data = { 
                    expression: this.expression
                }
                Object.assign(data, this.payload);

                let newItems: Dynamic<string>[] | undefined = undefined;
                if (this.endPoint) {
                    newItems = await SecureFetch.get<Dynamic<string>[]>(this.endPoint, data);                        
                } else if (this.query) {
                    newItems = await this.query(data);
                }

                if (newItems) {
                    this.itemsClear();
                    this.selectedIndex = Math.min(this.selectedIndex, newItems.length - 1);
                    
                    newItems.forEach(i => {
                        this.items.push(i);
                    })
                    this.itemsVisible = true;
                }
            },
            itemsClear() {
                this.items.splice(0, this.items.length);
            },
            itemSelect() {
                if (this.selectedItem) {
                    const display = this.displayExpression(this.selectedItem);
                    this.expression = this.expressionFromDisplay(display);
                    this.$emit("update:modelValue", this.valueExpression(this.selectedItem));                    
                    this.$emit("update:display", display);
                    this.$emit("item-selected", this.selectedItem);
                    this.itemsVisible = false;
                } else if (this.items.length === 0 && this.enableNoResultItem) {
                    this.$emit("item-no-result-selected", this.expression);
                    this.itemsVisible = false;
                }
            },
            itemSelectIndex(index: number) {
                this.selectedIndex = index;
                this.itemSelect();
            },
            localize(value: string): string {
                if (!this.enableLocalization) return value;
                return this.localization.value(value) ?? "";
            },
            goUp() {
                if (this.selectedIndex - 1 > -1) {
                    this.selectedIndex--;
                    this.insureItemVisible();
                }                
            },
            goDown() {
                if ((this.selectedIndex + 1) < this.items.length) {
                    this.selectedIndex++;
                    this.insureItemVisible();
                }
            },
            insureItemVisible() {
                const container = this.$refs.divAutoCompleteItems as HTMLElement;
                const item = (this.$refs["i-" + this.selectedIndex.toString()] as HTMLAnchorElement[])[0];
                if ((item.offsetTop + item.clientHeight) > (container.scrollTop + container.clientHeight)) {
                    container.scrollTop = item.offsetTop - container.clientHeight + item.clientHeight;
                } else if (item.offsetTop < container.scrollTop) {
                    container.scrollTop = item.offsetTop;
                }
            },
            focus() {
                if (this.showOnFocus && !this.itemsVisible) {
                    this.itemsFetch();
                } else if (this.items.length > 0 || (this.enableNoResultItem && this.expression.length > this.minExpressionLength)) {
                    this.itemsVisible = true;
                }
            },
            blur() {
                setTimeout(() => {
                    this.itemsVisible = false;
                }, 250);                
            }
        }
    });
