
	import { defineComponent } from "vue";
	import DateTimeEditor from "../controls/date-time/DateTimeEditor.vue";
	import DialogBase from "./DialogBase.vue";

	interface DialogConfirmData {
		title: string,
		message: string,
		defaultValue: Date
	}

	export default defineComponent({
		extends: DialogBase,		
		components: {
			DateTimeEditor
		},
		data() {
			const dialogDataTyped = this.dialogDataGet<DialogConfirmData>();
			return {
				dialogDataTyped,
				value: dialogDataTyped.defaultValue
			}
		},
		methods: {
			ok() {
				this.returnOk(this.value);
			},
			cancel() {
				this.returnCancel();
			}
		}
	});
