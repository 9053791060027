import LocalizationManager from "@/localization/LocalizationManager";

export class DateUtilitiesContext {
    private static instance: DateUtilitiesContext;
   
    public static instantiate() {
        if (!this.instance) {
            this.instance = new DateUtilitiesContext();
        }
        return this.instance;
    }

    public localDateString(d?: Date, showTime = true, defaultValue = ""): string {
        if (!d) return defaultValue;
        
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();
        let result = `${year}-${(month < 10 ? '0' : '') + month.toString()}-${(day < 10 ? '0' : '') + day.toString()}`;

        if (showTime) {
            const hours = d.getHours();
            const minutes = d.getMinutes();
            result += ` ${(hours < 10 ? '0' : '') + hours.toString()}:${(minutes < 10 ? '0' : '') + minutes.toString()}`;
        }

        return result;
    }

    public localTimeString(d?: Date, defaultValue = ""): string {
        if (!d) return defaultValue;
        
        const hours = d.getHours();
        const minutes = d.getMinutes();
        return `${(hours < 10 ? '0' : '') + hours.toString()}:${(minutes < 10 ? '0' : '') + minutes.toString()}`;
    }

    public utcDateString(d: Date, defaultValue = ""): string {
        if (!d) return defaultValue;
        
        const year = d.getUTCFullYear();
        const month = d.getUTCMonth() + 1;
        const day = d.getUTCDate();
        const hours = d.getUTCHours();
        const minutes = d.getUTCMinutes();
        
        return `${year}-${(month < 10 ? '0' : '') + month.toString()}-${(day < 10 ? '0' : '') + day.toString()} ${(hours < 10 ? '0' : '') + hours.toString()}:${(minutes < 10 ? '0' : '') + minutes.toString()} UTC / GMT`;
    }

    public utcDateTimeParsableStringFromDate(d: Date) {
        const year = d.getUTCFullYear();
        const month = d.getUTCMonth() + 1;
        const day = d.getUTCDate();
        const hours = d.getUTCHours();
        const minutes = d.getUTCMinutes();
        const seconds = d.getUTCSeconds();

        return this.utcDateTimeParsableString(year, month, day, hours, minutes, seconds);
    }

    public utcDateTimeParsableString(year: number, month: number, day: number, hours: number, minutes: number, seconds: number): string {        
        return `${year}-${(month < 10 ? '0' : '') + month.toString()}-${(day < 10 ? '0' : '') + day.toString()}T${(hours < 10 ? '0' : '') + hours.toString()}:${(minutes < 10 ? '0' : '') + minutes.toString()}:${(seconds < 10 ? '0' : '') + seconds.toString()}Z`;
    }

    public getTimeZoneName(d: Date) {
        const matches = d.toString().match(/\(.*?\)/);
        if (matches) {
            return matches[0].substring(1, matches[0].length - 1)
                             .split(" ")
                             .map(m => m.substring(0, 1)
                             .toUpperCase())
                             .join("");
        } else {
            return "Local Time";
        }
    }

    public minuteDifference(d1: Date, d2: Date): number {
        return Math.abs(d1.getTime() - d2.getTime()) / (1000 * 60);
    }
   
    public dayOfWeekName(dayOfWeek: number) {
        return LocalizationManager.languageData.dateTime.dayOfWeekNames[dayOfWeek];
    }

    public dayOfWeekAbbr(dayOfWeek: number) {
        return LocalizationManager.languageData.dateTime.dayOfWeekAbbreviations[dayOfWeek];
    }

    weekStartDateClosest(date: Date, firstDayOfWeek = 0) {
        const fromDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const initDayOfWeek = fromDate.getDay();
        if (initDayOfWeek < firstDayOfWeek) {
            fromDate.setDate(fromDate.getDate() - (initDayOfWeek + (7 - firstDayOfWeek)));					
        } else {
            fromDate.setDate(fromDate.getDate() - (initDayOfWeek - firstDayOfWeek));
        }
        return fromDate;
    }
    public millisecondsToTimeString(ms: number | undefined, showSeconds = false) {
        if (ms === undefined) {
            return undefined;
        }

        const h = Math.floor(ms / 3600000);
        ms = ms % 3600000;
        const m = Math.floor(ms / 60000);
        ms = ms % 60000;
        const s = Math.floor(ms / 1000);
   
        let result = `${(h < 10 ? '0' : '') + h.toString()}:${(m < 10 ? '0' : '') + m.toString()}`;
        if (showSeconds) {
            result += `:${(s < 10 ? '0' : '') + s.toString()}`
        }

        return result;
    }

    public minutesToTimeString(m: number | undefined) {
        if (m === undefined) {
            return undefined;
        }
        m = Math.round(m);
        const h = Math.floor(m / 60);
        m = m % 60;
   
        return `${(h < 10 ? '0' : '') + h.toString()}:${(m < 10 ? '0' : '') + m.toString()}`;
    }

    public hoursToTimeString(h: number | undefined) {
        if (h === undefined) {
            return undefined;
        }
        let m = Math.round((h % 1) * 60);
        h = Math.floor(h);

        if (m === 60) {
            h++;
            m = 0;
        }

        return `${(h < 10 ? '0' : '') + h.toString()}:${(m < 10 ? '0' : '') + m.toString()}`;
    }

    public millisecondsFromTimeOfDay(date: Date | undefined) {
        if (date === undefined) {
            return undefined;
        }

        return 3600000 * date.getHours() + date.getMinutes() * 60000 + date.getSeconds() * 1000 + date.getMilliseconds();
    }

    public percentageFromTimeOfDay(date: Date): number {
        return 100 * this.millisecondsFromTimeOfDay(date)! / 86400000;
    }
}

const DateUtilities = DateUtilitiesContext.instantiate();
export default DateUtilities;