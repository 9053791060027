import { copyPropertiesFrom } from "@/util/reflection";

export default class ConfigGeneralModel {	
	companyName: string;

	inactivityLogOutSeconds: number;

	inactivityPunchOutTime?: number;

	logoFileName: string;

	autoEndOperations: boolean;

	autoStartOperationsOnPunchIn: boolean;

	lateArrivalTolerance?: number;

	expectedTimePaidTolerance?: number;	

	unpaidBreakTolerance?: number;

	persistSubClassOverrideOnBreaks: boolean;

	constructor(configData: any) {
		copyPropertiesFrom(this, configData, ["logoFile"]);
	}
}