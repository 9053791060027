import Cookies from "@/util/cookies";
import type { LanguageData } from "./LanguageData";
import { SecureFetch } from "@/util/ajax";
import Settings from "@/settings";
import type { LanguageSetting } from "./LanguageSetting";

export class LocalizationManagerContext {
    private static instance: LocalizationManagerContext;

    public static instantiate() {
        if (!this.instance) {
            this.instance = new LocalizationManagerContext();
        }
        return this.instance;
    }

    language: LanguageSetting;

    defaultLanguage: LanguageSetting;

    languageData: LanguageData;

    async configure() {
        return await this.initialize();
    }

    async initialize() {
        this.defaultLanguage = Settings.languages.find(l => l.default)!;

        const cookie = Cookies.cookieGet(Settings.langCookieName);
        if (cookie) {            
            this.language = Settings.languages.find(l => l.key === cookie.Value)!;
        } else {
            this.language = this.defaultLanguage;
            this.languageCookieSet();
        }
        await this.languageDataLoad();
    }

    public async languageChange(language: LanguageSetting) {
        this.language = language;
        this.languageCookieSet();
        await this.languageDataLoad();
    }

    private async languageDataLoad() {
        this.languageData = await SecureFetch.get<LanguageData>(`/lang/${this.language.key}.json`);
    }

    private languageCookieSet() {
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 100);
        Cookies.cookieSet(Settings.langCookieName, this.language.key, expires);
    }

    stringValueParse(stringValue: string | undefined) {
		const valueObject: { [key:string]: string | undefined } = { };		
        if (!stringValue) return valueObject;
		try {
			const attempt = JSON.parse(stringValue);
            if (typeof(attempt) === "object") {
                Settings.languages.forEach(l => {
                    if (attempt[l.key]) {
                        valueObject[l.key] = attempt[l.key];
                    }						
                });
                return valueObject;
            }			
		} catch { 
            //
        }
        
        Settings.languages.forEach(l => {
            valueObject[l.key] = stringValue;				
        });
		return valueObject;
	}

    defaultValue(stringValue: string | undefined) {
        const valueObject = this.stringValueParse(stringValue);
        return valueObject[this.defaultLanguage.key];
    }

    value(stringValue: string | undefined) {
        const valueObject = this.stringValueParse(stringValue);
        return valueObject[this.language.key] ?? valueObject[this.defaultLanguage.key];
    }

    render(template: string, data: any) {
        const d: any = Object.assign({}, data);
        for (const p in data) {
            if (typeof(data[p]) === "string") {
                d[p] = this.value(data[p]);
            }
        }        
        return new Function("return `" + template + "`;").call(d) as string;
    }
}

export const LocalizationManager = LocalizationManagerContext.instantiate();
export default LocalizationManager;