import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "conditions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logic_designer_condition = _resolveComponent("logic-designer-condition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.container.conditions, (condition, ordinal) => {
      return (_openBlock(), _createBlock(_component_logic_designer_condition, {
        model: condition,
        parent: _ctx.container,
        ordinal: ordinal,
        "expression-items": _ctx.expressionItems,
        onConditionAdd: _ctx.conditionAdd,
        onConditionRemove: _ctx.conditionRemove,
        key: ordinal
      }, null, 8, ["model", "parent", "ordinal", "expression-items", "onConditionAdd", "onConditionRemove"]))
    }), 128)),
    _createElementVNode("a", {
      class: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.conditionAdd(_ctx.container);})
    }, " Add Condition ")
  ]))
}