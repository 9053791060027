import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "validation-summary"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.modelValue.isValid)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_ctx.validationTitle)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.validationTitle), 1)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.validationErrors, (error, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}