
    import type { Shared } from "@/localization/LanguageData";
    import LocalizationManager from "@/localization/LocalizationManager";
    import Settings from "@/settings";
    import { defineComponent } from "vue";

    export const ViewBase = defineComponent({
        data() {
            return {
                settings: Settings,
                localization: LocalizationManager
            }
        },
        computed: {
            shared(): Shared {
                return this.localization.languageData.shared
            }
        }
    })
    export default ViewBase;
