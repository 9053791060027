import { copyPropertiesFrom } from "@/util/reflection";

export default class UserModel {
    userID: number;

    employeeID: number;

    userName: string;

    firstName: string;

    lastName: string;

    displayName: string;

    email: string;

    profilePicture?: string = undefined;

    employeeTerminationDate?: Date;

    employeeTerminationReason?: string;

    constructor(userData: any) {
        copyPropertiesFrom(this, userData);
    }
}